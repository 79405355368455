body {
  margin: 0;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.flip {
  transform: scaleX(-1);
}

.MuiBackdrop-root.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.4);
}

svg {
  height: auto;
}

.paging-input {
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Poppins';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.numeric-editor {
  padding: 12px;
  border-radius: 6px;
  background-color: #F5F7FF;
  width: 50%;
  font-weight: 500;
  font-size: 0.875rem;
}

.manufacturer-image {
  width: 150px;
  height: 80px;
  object-fit: cover;
}

.hashtag {
  color: #52CBFF;
}